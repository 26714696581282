import React from 'react';

// Assets
import close from '../../../assets/img/close.png';
import closeHover from '../../../assets/img/closeHover.png';
import closePush from '../../../assets/img/closePush.png';
import modalSuccess from '../../../assets/img/successModal.png';

// Styles
import { Overlay, Modal, ModalImgBought, ModalInfo } from '../../../shared/styles/GeneralStyles';

const ModalBought = (props: any) => {
	const [isOpen, setIsOpen] = React.useState(true)
	const [hover, setHover ] = React.useState(false)
	const [push, setPush] = React.useState(false)

	function closeModal() {
		setIsOpen(false);
	}

	const hovering = () => {
		setHover(true)
	}

	const hoverOut = () => {
		setHover(false)
	}

	const mouseDown = () => {
		setPush(true)
	}

	return (
		<div>
			{ isOpen ?
				<Overlay>
				<Modal>
					<button type="button" className="close-btn" onMouseUp={closeModal} onMouseEnter={hovering} onMouseLeave={hoverOut} onMouseDown={mouseDown}>
						{ hover ?
							<>
								{ push ?
									<img src={closePush} alt="Close icon" />
									: <img src={closeHover} alt="Close icon" />
								}
							</>
							: <img src={close} alt="Close icon" />
						}
					</button>
					<ModalImgBought src={modalSuccess} alt="Skull img" />
					<ModalInfo>You successfully bought 
						<span> 
							{ props.count } { props.count === 1 ? ' skillet.' : ' skilletts.' } 
						</span>
					</ModalInfo>
					<button type="button" onMouseUp={closeModal} className="modal-btn">
						ok
					</button>
				</Modal>
			</Overlay>
				: null
			}
		</div>

	);
};

export default ModalBought
