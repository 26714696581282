export const color = {
    green: '#20F506',
    black: '#191919',
    blackest: '#000000',
    white: '#fff',
    ctaHover: '#2B6431',
    ctaActive: '#1D4221',
}

const size = {
    desktop: '1366px',
    tabletL: '1024px',
    tablet: '768px',
    mobile: '525px',
    mobileS: '414px',
    mobileXS: '360px',
}

export const device = {
    desktop: `(max-width: ${size.desktop})`,
    tabletL: `(max-width: ${size.tabletL})`,
    tablet: `(max-width: ${size.tablet}),`,
    mobile: `(max-width: ${size.mobile})`,
    mobileS: `(max-width: ${size.mobileS})`,
    mobileXS: `(max-width: ${size.mobileXS}),`
}