import React from 'react';

import InputNumber from '../InputNumber'

// Assets
import welcome from '../../../assets/img/asset1.png';

// Styles
import { Container, InsideContainer, Welcome, MainDiv } from './style';

const Main = (props: any) => (
	<MainDiv>
	<Welcome src={welcome} alt="Welcome to skillet" />
	<Container>
		<InsideContainer>
			<InputNumber wrongNetwork={props.wrongNetwork} />
		</InsideContainer>
	</Container>
	</MainDiv>
);

export default Main