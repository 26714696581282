import styled from "styled-components";
import { color, device } from '../../../shared/styles/variables';

export const BottomWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const InputContainer = styled.div`
    p {
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        text-shadow: -2px -2px 0 ${color.blackest}, 2px -2px 0 ${color.blackest}, -2px 2px 0 ${color.blackest}, 2px 2px 0 ${color.blackest};
    }
`;

export const InputWrap = styled.div`
    display: flex;
    width: 100%;
`;

export const PlusMinusWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        max-width: 63px;
    }

    @media ${device.tablet} {
        img {
            max-width: 50px;
        }
    }
`;

export const InputDiv = styled.div`
    display: flex;
    align-items: center;
    width: 180px;
    max-height: 104px;
    position: relative;
    z-index: 1;
`;

export const Input = styled.input`
    width: 100%;
    height: 100%;
    max-width: 80%;
    border: none;
    padding: 0;
    outline: none;
    background: none;
    color: ${color.green};
    text-align: center;
    font-family: inherit;
    font-size: 36px;
    line-height: 52px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -moz-appearance: textfield;
    text-shadow: -2px -2px 0 ${color.blackest}, 2px -2px 0 ${color.blackest}, -2px 2px 0 ${color.blackest}, 2px 2px 0 ${color.blackest};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

export const GifWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 300px;
    max-height: 310px;

    .gif {
        width: 180px;
        height: 175px;
        position: absolute;
        top: 75px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
`;

export const Price = styled.img`
    max-height: 50px;
`;
