import React from 'react';

// Assets
import soldOut from '../../../assets/img/sold-out.png';

// Styles
import { SoldOutWrap } from './style';

const SoldOut = () => (
	<SoldOutWrap>
		<img src={soldOut} alt="Sold out img" />
	</SoldOutWrap>
);

export default SoldOut