import React, { useState } from 'react';

// Assets
import close from '../../../assets/img/close.png';
import closeHover from '../../../assets/img/closeHover.png';
import closePush from '../../../assets/img/closePush.png';
import wrongNetwork from '../../../assets/img/wrong-network.png';

// Style
import { Modal, ModalImg, Overlay, ChangeNetwork } from '../../../shared/styles/GeneralStyles'

const ModalConnection = (props: any) => {
	const [hover, setHover ] = useState(false)
	const [push, setPush] = useState(false)

	const hovering = () => {
		setHover(true)
	}

	const hoverOut = () => {
		setHover(false)
	}

	const mouseDown = () => {
		setPush(true)
	}

	return (
		<div className="modal-wrap">
			<Overlay>
				<Modal>
					<button type="button" className="close-btn" onMouseUp={props.closeModal} onMouseEnter={hovering} onMouseLeave={hoverOut} onMouseDown={mouseDown}>
						{ hover ?
							<>
								{ push ?
									<img src={closePush} alt="Close icon" />
									: <img src={closeHover} alt="Close icon" />
								}
							</>
							: <img src={close} alt="Close icon" />
						}
					</button>
					<ModalImg src={wrongNetwork} alt="Skull img" />
					<ChangeNetwork>
						<span>Please change your network to Ethereum Mainnet to continue</span>
					</ChangeNetwork>
					<button type="button" onClick={props.closeModal} className="modal-btn">
						ok
					</button>
				</Modal>
			</Overlay>
		</div>
	)
}

export default ModalConnection