import React, {useState} from 'react';
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import { ToastContainer } from 'react-toastify';

import Header from './containers/general/Header';
import Footer from './containers/general/Footer';
import Landing from './containers/pages/Landingpage';

// General styles
import GeneralStyles from './shared/styles/GeneralStyles';
import GlobalFonts from './assets/fonts/fonts';

function getLibrary(provider: any){
  return new Web3(provider)
}

function App() {
  const [checkNetwork, setCheckNetwork] = useState(false)

  const wrongNetwork = () => {
    setCheckNetwork(true)
  }

  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ToastContainer position='bottom-right' theme='dark' />
        <GeneralStyles/>
        <GlobalFonts/>
        <Header network={checkNetwork} />
        <Landing wrongNetwork={wrongNetwork}/>
        <Footer />
      </Web3ReactProvider>
    </>
  );
}

export default App;
