import React, {useState, useEffect} from 'react';
import { useWeb3React } from '@web3-react/core'
import Web3 from 'web3'

// Toastify notifications
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import { injected } from '../../../components/wallet/connectors';

import ModalConnection from '../../pages/ModalConnection';

// Styles
import { HeaderWrap, HeaderInner } from '../style';

// Assets
import turnoff from '../../../assets/icon/turn-off.svg';

const Header = (props: any) => {
	let web3;
	let netPromise;
	const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 1;
	const [modalVisibility, setModalVisibility] = useState(false)
	const [mainNet, setMainNet] = useState(false);
	const { active, account, activate, deactivate } = useWeb3React()
	const [notificationVisable, setNotificationVisable] = useState(props.network)

	useEffect(() => {
		setNotificationVisable(props.network);
	}, [props])
	async function connect(){
		setNotificationVisable(false)
		if(!active){
			try {
				await activate(injected)
				web3 = new Web3(Web3.givenProvider)
				netPromise = web3.eth.net.getId();
				netPromise.then((val: number) => {
					if(val !== chainId){
						setModalVisibility(true)
						setMainNet(false)
						deactivate()
					} else {
						setMainNet(true)
					}
				});
			} catch(ex){
				toast.error("Wrong network");
			}
		} else {
			deactivate()
		}
	}

	const closeClick = () => {
		setModalVisibility(false)
	}

	return (
		<HeaderWrap>
			<HeaderInner>
				<button type="button" className="connect-btn" onClick={connect}>
					{active && mainNet ? <><span className="desktop-rez"><span>{`${account!.substring(0, 4)}...${account!.slice(-6)}`}</span> <img src={turnoff} alt="Turn off icon" className="off" /></span><span className="mobile-rez"><span>{`${account!.substring(0, 4)}...${account!.slice(-2)}`}</span> <img src={turnoff} alt="Turn off icon" className="off" /></span></> : <><span className="desktop-res">Connect wallet</span></>}
					{notificationVisable &&
						<div className="mint-notifiaction">
							<p>Please connect your wallet to start minting</p>
						</div>
					}
				</button>
				{modalVisibility && <ModalConnection closeModal={closeClick} />}
			</HeaderInner>
		</HeaderWrap>
	)
}

export default Header;
