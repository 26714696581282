import React from 'react';

// Assets
import twitter from '../../../assets/img/twitter.png';
import ether from '../../../assets/img/etherscan.png';
import opensea from '../../../assets/img/opensea.png';

// Styles
import { FooterWrap, SocialLogo, SocialIcons } from '../style';

const Footer = () => (
	<FooterWrap>
		<SocialIcons>
			<a href="https://twitter.com/skillettnft" target="_blank" rel="noreferrer">
				<SocialLogo src={twitter} alt="Twitter logo" />
			</a>
			<a href="https://goerli.etherscan.io/address/0x90e4295e7f5020b09828e6ebbe2e833dcddbd2d2" target="_blank" rel="noreferrer">
				<SocialLogo src={ether} alt="Ether scan logo" />
			</a>
			<a href="https://opensea.io/collection/skillett" target="_blank" rel="noreferrer">
				<SocialLogo src={opensea} alt="Open sea logo" />
			</a>
		</SocialIcons>
	</FooterWrap>
);

export default Footer;