import React from 'react'

import Main from '../Main'; 

// Styles
import { Wrapper } from './style';

const Landing = (props: any) => (
	<Wrapper>
		<Main wrongNetwork={props.wrongNetwork} />
	</Wrapper>
);

export default Landing
