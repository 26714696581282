import { createGlobalStyle } from 'styled-components';

import PermanentMarker from './PermanentMarker-Regular.woff';
import PermanentMarker2 from './PermanentMarker-Regular.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Permanent Marker';
        src: local('Permanent Marker'), local('Permanent Marker'),
        url(${PermanentMarker}) format('woff'),
        url(${PermanentMarker2}) format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;