import styled from 'styled-components';

export const SoldOutWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 280px;
    }
`;