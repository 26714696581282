import styled, { keyframes } from 'styled-components';
import { device, color } from '../../shared/styles/variables';


const notificationAnimation = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

// Header
export const HeaderWrap = styled.header`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
`;

export const HeaderInner = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 100px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    .connect-btn {
        position: relative;

        .mobile-rez {
            display: none;
        }
    }

    .mint-notifiaction {
        display: block;
        width: 100%;
        min-width: 288px;
        background: ${color.black};
        border: 2px solid ${color.green};
        box-shadow: 0px 0px 5px ${color.green};
        position: absolute;
        top: 75px;
        right: 45px;
        padding: 10px 30px;
        box-sizing: border-box;
        text-align: left;
        animation: ${notificationAnimation} 0.20s ease-out forwards;

        p {
            max-width: 188px;
            position: relative;

            &:before,
            &:after {
                content: "";
                width: 0px;
                height: 0px;
                position: absolute;
            }

            &:before {
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid ${color.green};
                right: -50px;
                top: -25px;
            }

            &:after {
                border-left: 12px solid transparent ;
                border-right: 12px solid transparent;
                border-bottom: 12px solid ${color.black};
                right: -47px;
                top: -21px;
            }
        }

        @media ${device.mobile} {
            top: 60px;
            right: 0;
        }
    }

    @media ${device.tabletL} {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media ${device.mobile} {
        padding: 20px;

        .connect-btn {
            width: 190px;
            display: flex;
            justify-content: center;

            .desktop-rez {
                display: none;
            }

            .mobile-rez {
                display: flex;
            }
        }
    }
`;

// Footer
export const FooterWrap = styled.footer`
    display: flex;
    justify-content: center;
    width: 100%;

    div a {
        display: inline-flex;
    }
`;

export const SocialIcons = styled.div`
    display: flex;
`;

export const SocialLogo = styled.img`
    max-width: 80px;
`;