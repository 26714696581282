import styled, { createGlobalStyle, keyframes } from "styled-components";
import { color, device } from './variables'

// Assets
import bodyImg2k from '../../assets/img/background3200.jpg';
import bodyImg from '../../assets/img/backgroundDesk1920.jpg';
import bodyImgTablet from '../../assets/img/backgroundMob.jpg';

const modalAnimation = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

export default createGlobalStyle`
    html,
    body,
    #root {
        height: 100%;
        min-height: 100%;
        margin: 0;
        overflow-x: hidden;
        font-family: 'Permanent Marker';
    }

    body {
        height: 100%;
        font-size: 100%;
        color: ${color.green};
        background: url(${bodyImg}) no-repeat center center;
        background-size: cover;

        @media (min-width: 1921px) {
            background: url(${bodyImg2k}) no-repeat center center;
            background-size: cover;
        }

        @media ${device.tabletL} {
            background: url(${bodyImgTablet}) no-repeat center center;
            background-size: cover;
        }
    }

    h1,
    h2,
    p {
        margin: 0;
    }

    button {
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .connect-btn,
    .modal-btn {
        box-sizing: border-box;
        border: 2px solid ${color.green};
        box-shadow: 0px 0px 5px 1px ${color.green};
        transition: background 100ms ease-out;

        &:hover {
            background-color: ${color.ctaHover};
        }

        &:active {
            background-color: ${color.ctaActive};
        }

        &:disabled {
            background-color: ${color.blackest};
            opacity: 30%;
        }
    }

    .modal-btn {
        background: rgba(32, 245, 6, 0.15);
    }

    .connect-btn,
    .modal-btn {
        background-color: ${color.blackest};
    }

    .connect-btn {
        max-width: 193px;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        line-height: 21px;
        padding: 10px 35px;
        color: ${color.green};

        span {
            display: flex;
            align-items: center;

            .off {
                max-height: 21px;
                margin-left: 11px;
            }
        }

        @media ${device.mobile} {
            padding: 8px;
        }
    }

    button.disable {
        pointer-events: none;
        opacity: 0.3;
    }

    .modal-wrap {
        display: block;
    }

    .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .modal-btn {
        width: 100%;
        max-width: 170px;
        font-size: 40px;
        line-height: 32px;
        font-family: 'Permanent Marker';
        padding: 12px;
        color: ${color.green};
    }

    .modal-wrap2 {
        display: none;
    }
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.35);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 642px;
    padding: 72px 20px 30px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: ${color.black};
    border: 2px solid ${color.green};
    box-shadow: 0px 0px 5px #26F216;
    color: ${color.green};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    animation: ${modalAnimation} 0.20s ease-out forwards;


    h2,
    p {
        text-align: center;
    }
`;


export const ModalImg = styled.img`
    max-width: 217px;
    margin-bottom: 32px;
`;

export const ModalImgBought = styled.img`
    max-width: 189px;
    margin-bottom: 32px;
`;

export const ModalHeading = styled.h2`
    font-size: 24px;
    line-height: 64px;
`;

export const ChangeNetwork = styled.p`
    font: 18px/21px Roboto, sans-serif;
    margin-bottom: 30px;
`;

export const ModalInfo = styled.p`
    font-size: 18px;
    line-height: 21px;
    font-family: 'Roboto';
    color: ${color.white};
    margin-bottom: 44px;

    span {
        text-transform: uppercase;
        margin-left: 5px;
        color: ${color.green}
    }
`;


