import styled from "styled-components";
import { device } from "../../../shared/styles/variables";

export const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Container = styled.div`
    width: 100%;
    max-width: 300px;
    margin-bottom: 56px;

    @media ${device.mobile} {
        margin-bottom: 26px;
    }
`;

export const InsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Welcome = styled.img`
    max-width: 300px;
`;
