import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core'

// Toastify notifications
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import skillettAbi from '../../../abi/abi.json'

import ModalBought from '../ModalBought';
import SoldOut from '../../../shared/components/SoldOut';

// Assets
import numberI from '../../../assets/img/asset2.png'
import price from '../../../assets/img/price1.png';
import gif from '../../../assets/img/gifskillet.gif';
import plus from '../../../assets/img/plus.png';
import minus from '../../../assets/img/minus.png';
import mint from '../../../assets/img/mint.png';
import borderGif from '../../../assets/img/image-border.png'
import inputBorder from '../../../assets/img/input-wrap.png'
import mintHover from '../../../assets/img/ctaHov.png';
import mintPush from '../../../assets/img/ctaPush.png';

// Styles
import { BottomWrap, InputContainer, InputWrap, PlusMinusWrap, Input, InputDiv, GifWrap, Price } from './style';

const InputNumber = (props: any) => {
	const { library } = useWeb3React()
	const [value, setValue] = useState(1)
	const [totalSupply, setTotalSupply] = useState(1000)
	const [address, setAddress] = useState('')
	const [contract, setContract] = useState<any>()
	const [isMinted, setIsMinted] = useState(false)
	const [count, setCount] = useState(0)
	const [disabledPlus, setDisabledPlus] = useState(false)
	const [disabledMinus, setDisabledMinus] = useState(true)
	const [hover, setHover] = useState(false)
	const [push, setPush] = useState(false)

	useEffect(() => {
		const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS || '';
		const setData = async () => {
			setAddress(contractAddress)
			const provider = new ethers.providers.Web3Provider(library.currentProvider);
			const signerUser = await provider.getSigner();
			const contractInit = new ethers.Contract(contractAddress, skillettAbi, signerUser);
			setContract(contractInit)
		}
		const fetchSupply = async () => {
			const url = process.env.REACT_APP_PROVIDER_URL || '';
			const supplyProvider = new ethers.providers.JsonRpcProvider(url);
			const supplyContract = new ethers.Contract(contractAddress, skillettAbi, supplyProvider);
			const supply = await supplyContract.totalSupply();
			setTotalSupply(1000 - ethers.BigNumber.from(supply).toNumber());
		}
		if (library && !address) {
			setData()
				.then()
				.catch((error: any) => console.log(error));
		}

		fetchSupply()
			.then()
			.catch((error: any) => console.log(error));
	}, [address, contract, library])

	const renderTransactionToast = async (waitForTransaction: any) => {
		if (waitForTransaction.hash) {
			toast.info('Transaction submitted to the Blockchain!');
			const result = await waitForTransaction.wait();

			if (result.status === 0) {
				toast.error('Transaction Failed!');
			}
			if (result.status === 1) {
				setIsMinted(true);
				const supply = await contract.totalSupply();
				setTotalSupply(1000 - ethers.BigNumber.from(supply).toNumber());
				toast.success(`Transaction Successful!`);
			}
		}
	}

	function clickPlus(){
		setValue(prevValue => prevValue + 1)
		setDisabledMinus(false)
		if(value === 9){
			setDisabledPlus(true)
		}
	}

	function clickMinus(){
		setValue(prevValue => prevValue - 1)
		setDisabledPlus(false)
		if(value === 2){
			setDisabledMinus(true)
		}
	}

	function handleInputChange(e: React.ChangeEvent<HTMLInputElement>){
		const {target} = e;
		const newValue = Number(target.value);
		if(newValue < 0 || newValue > 10){
			return
		}
		if(newValue === 1){
			setDisabledMinus(true)
		} else {
			setDisabledMinus(false)
		}
		if(newValue === 10){
			setDisabledPlus(true)
		} else {
			setDisabledPlus(false)
		}
		setValue(newValue)
	}

	async function minting(){
		if(library){
			try {
				const maxMintCount = Number(process.env.REACT_APP_MAX_MINT_COUNT) || 10
				const countNfts = value > maxMintCount ? maxMintCount : value;
				setCount(countNfts);
				const nftValue = parseFloat(process.env.REACT_APP_NFT_VALUE || '0.05') * countNfts;
				const receipt = await contract.mintTokens(countNfts, { value: ethers.utils.parseEther(nftValue.toString()) })
				await renderTransactionToast(receipt);
			} catch(error: any){
				if(error.code === 'INSUFFICIENT_FUNDS') toast.error("Insufficient funds for transaction cost");
			}
		} else {
			props.wrongNetwork()
		}
	}

	const hovering =() => {
		setHover(true)
	}

	const hoverOut =() => {
		setHover(false)
	}

	const mouseDown =() => {
		setPush(true)
	}

	const mouseUp =() => {
		setPush(false)
	}

	return (
		<>
				{ totalSupply === 0 ? <SoldOut /> :
					<BottomWrap>
						<img src={numberI} alt="1000 new Skillett" />
						<GifWrap>
							<img src={borderGif} alt="Gif border" />
							<img src={gif} alt="Welcome to Skillet" className="gif"/>
						</GifWrap>
						<Price src={price} alt="Price" className="price"/>
						<InputContainer>
						<InputWrap>
							<InputDiv>
								<img src={inputBorder} alt="Input border" />
								<Input type="number" value={Number(value).toString()} min="1" max="10" onChange={(e) => handleInputChange(e)}/>
							</InputDiv>
							<PlusMinusWrap>
								<button type="button" onClick={clickPlus} className={`plusBtn${disabledPlus ? ' disable' : ''}`}>
									<img src={plus} alt="Plus" />
								</button>
								<button type="button" onClick={clickMinus} className={`minusBtn${disabledMinus ? ' disable' : ''}`}>
									<img src={minus} alt="Minus" />
								</button>
							</PlusMinusWrap>
						</InputWrap>
						<p>left { totalSupply }/1000</p>
						</InputContainer>
						<>
							{ value >= 1 ?
								<button onClick={minting} onMouseEnter={hovering} onMouseLeave={hoverOut} onMouseDown={mouseDown} onMouseUp={mouseUp} type="button">
									{ hover ?
										<>
											{ push ? <img src={mintPush} alt="Mint btn" /> : <img src={mintHover} alt="Mint btn" />}
										</>
										: <img src={mint} alt="Mint btn" />
									}
								</button> :
								<button disabled type="button">
									<img src={mint} alt="Mint btn" />
								</button>
							}
						</>
					</BottomWrap>
				}
			{ isMinted && <ModalBought count={count}/> }
		</>
	)
};

export default InputNumber
